import React from "react";

// We'll use ethers to interact with the Ethereum network and our contract
import { ethers } from "ethers";

// We import the contract's artifacts and address here, as we are going to be
// using them with ethers
import RLTTokenArtifact from "../contracts/RLTToken.json";
import RandomLottoArtifact from "../contracts/RandomLotto.json";
import USDTMockArtifact from "../contracts/USDTMock.json";

import contractAddress from "../contracts/contract-address.json";

import { NoWalletDetected } from "./NoWalletDetected";
import { ConnectWallet } from "./ConnectWallet";
import { Loading } from "./Loading";

import { LogoWidget } from "./LogoWidget"
import { AdminWidget } from "./AdminWidget"
import { WinnersHistoryWidget } from "./WinnersHistoryWidget"
import { StatusPanel } from "./StatusPanel"
import { AnnouncementWidget } from "./AnnouncementWidget"
import { ParticipationWidget } from "./ParticipationWidget";

import logoImage from "../images/logo.jpeg";

const TARGET_CHAIN_ID = contractAddress.chainID;

// This is an error code that indicates that the user canceled a transaction
const ERROR_CODE_TX_REJECTED_BY_USER = 4001;


export class Dapp extends React.Component {
  constructor(props) {
    super(props);
  
    this.initialState = {
      tokenData: undefined,
      selectedAddress: undefined,

      networkError: undefined,
            
      participantReview: [],

      lottoUsdtProperty : undefined,
      owner2UsdtProperty : undefined,
      owner3UsdtProperty : undefined,
      owner4UsdtProperty : undefined,

      rewardList: undefined,

      currentRound: undefined,
      isRoundStarted : undefined,
      myUsdt : undefined,

      errMessage: undefined,
      participationErr : undefined,

      showLoading : false,

      isOwner : false,
      announcement : undefined,
      shouldRerender : 0
    };
    
    this.state = this.initialState;
    if (window.ethereum !== undefined && !this.state.selectedAddress) {
      this._connectWallet();
    }
  }

  renderConnect = () =>{
    return (
      (<div>        
        <div style={{textAlign: 'center'}}>
          <span>Please connect your wallet to use this service!!</span>
        </div>
        <ConnectWallet 
          connectWallet={()=> {
              if (window.ethereum === undefined ) {
                return alert(" No Ethereum wallet was detected.!!\nplease, install Coinbase Wallet or MetaMask app ")
              }
              this._connectWallet();
            }
          } 
          networkError={this.state.networkError}
          dismiss={() => this._dismissNetworkError()}
        />
      </div>
      )
    );
  }
  
  renderWallet = () =>{
    return <NoWalletDetected/>
  };

  render() {
  
    return (
      
      <div className="container p-4 p-sm-1" >

        { this.state.showLoading && <Loading />}

        <div className="mt-3">
        <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
          <img src={logoImage} alt="logo" style={{width: '200px', height: '200px', borderRadius: '50%'}} />
        </div>
        </div> 

        {window.ethereum === undefined && this.renderWallet()}
        {!this.state.selectedAddress && this.renderConnect()}
 
      {this.state.selectedAddress && !this.state.tokenData &&
        <div className="mt-3 mb-5 d-flex flex-column align-items-center">
          <span style={{textAlign: 'center'}}>
            정보를 읽어오는 중입니다. 
          </span>
          <div className="spinner-border text-light mt-3" role="status"></div>
          <span className="text-center small mt-2">
            잠시 기다려주세요.<br/>시간이 오래걸릴 경우<br/>싸이트와 지갑 연결을 확인해주시고, 리로드 해주세요 
          </span>
          <div>
          
          </div>  
        </div>
      }


        <LogoWidget 
          isStarted={this.state.isRoundStarted} 
          currentRound={this.state.currentRound} 
          key={`${this.state.shouldRerender}-LogoWidget`}
        />
      

        {this.state.isOwner && 
        <AdminWidget 
          key={`${this.state.shouldRerender}-AdminWidget`}  
          lottoUsdtProperty={this.state.lottoUsdtProperty} 
          owner2UsdtProperty={this.state.owner2UsdtProperty} 
          owner3UsdtProperty={this.state.owner3UsdtProperty} 
          owner4UsdtProperty={this.state.owner4UsdtProperty}
          startRound={this._startRound} 
          finishRound={this._finishRound}
          setAnnouncement={this._setAnnouncement}
        />
        }
        
        <AnnouncementWidget 
          key={`${this.state.shouldRerender}-AnnouncementWidget`}
          announcement = {this.state.announcement}          
        />
        
        {/* {this.renderMenuButtons()} */}

        <div style={{color:"red"}}  className="mt-3">
          <b>{this.state.errMessage?.length > 0 ? this.state.errMessage : ''}</b>
        </div>
        
        <ParticipationWidget 
        key={`${this.state.shouldRerender}-ParticipationWidget-${this.state.participationErr}`}
        
          selectedAddress={this.state.selectedAddress} 
          Dapp={this}
          participationErr= {this.state.participationErr}
        />
        
    
        {this.state.participantReview.length > 0 &&
          <StatusPanel
            key={`${this.state.shouldRerender}-StatusPanel`}
            participantReview={this.state.participantReview}
            
          />
          }

        <WinnersHistoryWidget 
          key={`${this.state.shouldRerender}-WinnersHistoryWidget`}
          currentRound={this.state.currentRound} 
          randomLotto={this._randomLotto} 
          myUsdt={this.state.myUsdt}            
          withdrawReward={this._withdrawReward}
        />
    </div>
    );
  }
 



  _startRound = async () =>{
    if (!window.confirm("Are you sure you want to start accepting participants?")) {return;}
    this.setState({showLoading : true});
    try{
      const tx = await this._randomLotto.startRound();
      const receipt = await tx.wait();
      if (receipt.status === 0) {
        throw new Error("Transaction failed for unknown error");
      }
      await this._reload();
      
    } catch (error) {
      if (ERROR_CODE_TX_REJECTED_BY_USER === error.code) {
        this.setState({ 
          errMessage: "Transaction failed: User rejection"
        });
        return;
      }
      this.setState({ 
        errMessage: "Transaction failed: " + error.code + "\n" + error.reason
      });
    }finally{
      this.setState({showLoading : false});
    }
  }

  _finishRound = async () => {
    this.setState({showLoading : true});
    try{
      const tx = await this._randomLotto.finishRound();
      const receipt = await tx.wait();
      if (receipt.status === 0) {
        throw new Error("Transaction failed2");
      }
      await this._reload();    
      alert("라운드 종료 완료");
    } catch (error) {
      if (ERROR_CODE_TX_REJECTED_BY_USER === error.code) {
        this.setState({ 
          errMessage: "Transaction failed: User rejection"
        });
        return;
      }
      this.setState({ 
        errMessage: "Transaction failed: " + error.code + "\n" + error.reason
      });
    }finally{
      this.setState({showLoading : false});
    }
  }

 
  async _getTokenData() {
    try {
      const name = await this._rltToken.name();
      const symbol = await this._rltToken.symbol();
      this.setState({ tokenData: { name, symbol } });
      return true;
    } catch (error) {
      console.log(error.message);
      alert("Error occurred while fetching token data:", error.message);
      this.setState({ tokenData: null });
    }
    return false;
  }
  
  _getOwnersUsdt = async () => {
    const usdt1 = await this._randomLotto.getUSDTBalance();
    const usdt2 = await this._randomLotto.getOwner2USDTBalance();
    const usdt3 = await this._randomLotto.getOwner3USDTBalance();
    const usdt4 = await this._randomLotto.getOwner4USDTBalance();
    this.setState({
      lottoUsdtProperty: usdt1,
      owner2UsdtProperty : usdt2,
      owner3UsdtProperty : usdt3,
      owner4UsdtProperty : usdt4
    });    
    
  }


  _getCurrentRound = async () => {
    const round = await this._randomLotto.getRound();
    this.setState({
      currentRound: round,
    });
  } 

  _getRoundStarted = async () => {
    const v = await this._randomLotto.isRoundStarted();
    this.setState({
      isRoundStarted: v,
    });
  } 

  _getIsOwner = async () => {
    const iamOwner = await this._randomLotto.isInOwners();
    this.setState({
      isOwner: iamOwner,
    });
    if (iamOwner) {
      await this._getOwnersUsdt(iamOwner);
    }
  } 

  _getAnnouncement = async () => {
    const msg = await this._randomLotto.getAnnouncement();
    this.setState({
      announcement: msg,
    });
  } 

  _setAnnouncement = async (msg) => {
    this.setState({showLoading : true});
    await this._randomLotto.setAnnouncement(msg);
    this.setState({
      shouldRerender : this.state.shouldRerender + 1,
      announcement: msg,
      showLoading : false
    });
  } 



  _getMyReward = async () => {
    const reward = await this._randomLotto.getMyReward();
    const parsedReward = ethers.utils.formatUnits(reward, 18);
    this.setState({ 
      myUsdt: parsedReward
    });    
  } 

  _withdrawReward = async() => {
    this.setState({showLoading : true});
    try{
      const tx = await this._randomLotto.withdrawReward();
      const receipt = await tx.wait();
      this.setState({ 
        errMessage: ""
      });

      if (receipt.status === 0) {
        throw new Error("Transaction failed2");
      }
      await this._reload();
      alert("Withdrawal request completed");
    } catch (error) {
      if (ERROR_CODE_TX_REJECTED_BY_USER === error.code) {
        this.setState({ 
          errMessage: "Transaction failed: User rejection"
        });
        return;
      }
      this.setState({ 
        errMessage: "Transaction failed: " + error.code + "\n" + error.reason
      });
    }finally{
      this.setState({showLoading : false});
    }
  } 
 

  async _lotto(gameType, amount) {
    if (amount > 1000) {
      this.setState({ participationErr: "Total participation amount should be 1000 USDT or less for healthy participation"});
      return;
    }
    if (!this.state.selectedAddress){
      this.setState({ participationErr: "Please connect your wallet( or please click the Connect button)"});
      return;
    }
    if (!Number.isInteger(Number(amount)) || Number(amount) <= 0) {
      this.setState({ participationErr: "The amount must be an integer and greather than 1" });
      return;
    }

    if ( (Number(gameType) || 0) === 0) {
      this.setState({ participationErr: "Please select a participation type" });    
      return;  
    }

    this.setState({showLoading : true});

    try{
      this.setState({ 
        participationErr: "Requesting permission for the RandomLottoContract to access " + amount + " of my USDT from the USDT Contract."
       });
            
      const txApprove = await this._usdtMock.approve(this._randomLotto.address, ethers.utils.parseUnits(amount, 18));
      const receipt1 = await txApprove.wait();
        if (receipt1.status === 0) {
        throw new Error("Transaction failed1");
      }
      
      this.setState({ participationErr: "Requesting RandomLottoContract to participate in the game with " + amount + " USDT." });

      //RandomLottoContract에게  게임 참여 요청
      const tx = await this._randomLotto.betWithUSDT(gameType, ethers.utils.parseUnits(amount, 18));      
      const receipt = await tx.wait();
     
     
      this.setState({ 
        participationErr: "Participation completed!!"
      });
     
      await this._reload();
      if (receipt.status === 0) {
        throw new Error("Transaction failed");
      }
    } catch (error) {
      if (ERROR_CODE_TX_REJECTED_BY_USER === error.code) {
        this.setState({ 
          participationErr: "Transaction failed: User rejection"
        });
        return;
      }
      this.setState({ 
        participationErr: "Transaction failed: " +error.code+"\n"+ error.reason
       });
    }finally{
      this.setState({showLoading : false});
    }

  } 


  async _getParticipants() {
    const list = await this._randomLotto.getParticipantReview();
    this.setState({
      participantReview : list
    });
  }
 

  async _connectWallet() {
    const [selectedAddress] = await window.ethereum.request({ method: 'eth_requestAccounts' });
    this._checkNetwork();
    await this._initialize(selectedAddress);
    this.setState({ errMessage: ""});

    // We reinitialize it whenever the user changes their account.
    window.ethereum.on("accountsChanged", async ([newAddress]) => {
      if (newAddress === undefined) {
        return this._resetState();
      }      
      await this._initialize(newAddress);
    });
  }

  async _initialize(userAddress) {
    this.setState({
      selectedAddress: userAddress,
    });
    
    this._initializeContracts();
    
    if (await this._reload() === false) {
      alert("An error occurred. Please check your wallet and try again.");
    }
  }

  async _initializeContracts() {
    this._provider = new ethers.providers.Web3Provider(window.ethereum);

    this._rltToken = new ethers.Contract(
      contractAddress.RLTToken,
      RLTTokenArtifact.abi,
      this._provider.getSigner(0)
    );

    this._randomLotto = new ethers.Contract(
      contractAddress.RandomLotto,
      RandomLottoArtifact.abi,
      this._provider.getSigner(0)
    );

    this._usdtMock = new ethers.Contract(
      contractAddress.USDTMock,
      USDTMockArtifact.abi,
      this._provider.getSigner(0)
    );
  }

   
  async _reload() {
    if (await this._getTokenData() === false){
      return false;
    }
    await Promise.all([      
      this._getIsOwner(),
      this._getParticipants(),
      this._getCurrentRound(),
      this._getRoundStarted(),
      this._getAnnouncement(),
      
      this._getMyReward()
    ]);
    
    const no = this.state.shouldRerender + 1;
    this.setState({shouldRerender:no}); 
    return true;
  }

  // This method just clears part of the state.
  _dismissNetworkError() {
    this.setState({ networkError: undefined });
  }

  // This is an utility method that turns an RPC error into a human readable
  // message.
  _getRpcErrorMessage(error) {
    if (error.data) {
      return error.data.message;
    }
    return error.message;
  }


  _resetState() {
    this.setState(this.initialState);
  }

  _intStringToHexString(intString) {
    const intValue = parseInt(intString, 10);
    const hexString = intValue.toString(16);
    return hexString;
  }

  async _switchChain() {
    
    const chainIdHex = "0x"+this._intStringToHexString(TARGET_CHAIN_ID);
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: chainIdHex }],
      });
      await this._initialize(this.state.selectedAddress);
    } catch (error) {
      if (error.code === -32002) {
        console.log("Network switch request is already in progress. Waiting for user response...");
      } else {
        console.error("Error occurred while switching network:", error);
      }
    }
  }

  async _checkNetwork() {
    const netVersion = await window.ethereum.request({ method: 'net_version' });
    if (netVersion !== TARGET_CHAIN_ID) {
      this._switchChain();
    }
  }
}
