import React from "react";
import { ethers } from "ethers";



export class AdminWidget extends React.Component {
  constructor(props) {
    super(props);
    this.isOwner = props.isOwner;
    this.lottoUsdtProperty = props.lottoUsdtProperty;
    this.owner2UsdtProperty = props.owner2UsdtProperty;
    this.owner3UsdtProperty = props.owner3UsdtProperty;
    this.owner4UsdtProperty = props.owner4UsdtProperty;

    this.startRound = props.startRound;
    this.finishRound = props.finishRound;
    this.setAnnouncement = props.setAnnouncement;

  };


  render() {
    return (
      <div className="mt-3">
        <h6>운영자 판넬</h6>
        <div style={{border: '0.5px solid lightgray', borderRadius: '5px', padding: '10px'}}>
          <div className="row">
            <div className="col-12 col-md-3" >
              프로그램's USDT - {(this.lottoUsdtProperty && ethers.utils.formatUnits(this.lottoUsdtProperty, 18)) || 0} USDT
            </div>
            <div className="col-12 col-md-3" >
              owner2's USDT - {(this.owner2UsdtProperty && ethers.utils.formatUnits(this.owner2UsdtProperty, 18)) || 0} USDT
            </div>
            <div className="col-12 col-md-3" >
              owner3's USDT - {(this.owner3UsdtProperty && ethers.utils.formatUnits(this.owner3UsdtProperty, 18)) || 0} USDT
            </div>
            <div className="col-12 col-md-3" >
              수수료 USDT - {(this.owner4UsdtProperty && ethers.utils.formatUnits(this.owner4UsdtProperty, 18)) || 0} USDT
            </div>
          </div>
          
          <div className="mt-3">
            <div>
              <span>공지사항 입력</span>
              <button className="btn btn-primary btn-sm py-0 ml-3" onClick={() => this.setAnnouncement(
                document.getElementById('adminAnnouncement').value
              )}>
                저장
              </button>
            </div>            
            <textarea className="form-control mt-2" id="adminAnnouncement"></textarea>
          </div>
          
          <div className="d-flex justify-content-start mt-3">
              <button className="btn btn-primary btn-sm mr-3" onClick={() => this.startRound()}>
                라운드 시작
              </button>
              <button className="btn btn-primary btn-sm" onClick={() => this.finishRound()}>
                라운드 종료
              </button>
          </div>

        </div>


      </div>    
    );
  }
}

export default AdminWidget;

