import React from "react";
import logoImage from "../images/logo.jpeg";


export class LogoWidget extends React.Component {
 
  constructor(props) {
    super(props);
    this.state = {
      currentRound : props.currentRound,
      isStarted : props.isStarted
    };
  };


  render() {
    return (
      <div className="mt-3">
        <h3 style={{textAlign: 'center'}}>
        Welcome to RandomLotto Draw!
        </h3>
        <h4 style={{textAlign: 'center', color: this.state.isStarted ? '#208B34' : 'red', fontWeight: 'bold'}}>
          Round {this.state.currentRound} is {this.state.isStarted ? 'in progress' : 'not started yet'}.
        </h4>        
        
      </div> 
    );
  }
}

export default LogoWidget;

