import React from "react";


export class AnnouncementWidget extends React.Component {
 
  constructor(props) {
    super(props);
    this.announcement = props.announcement;
  };


  render() {
    return (
      <div className="mt-3">
        <h6>Notice Information</h6>
        <div style={{border: '0.5px solid lightgray', borderRadius: '5px', padding: '10px'}}>
          <div style={{textAlign: 'left', color: 'lightgray', whiteSpace: 'pre-line'}}>
            {this.announcement ?? "No announcements yet."}
          </div>
        </div> 
      </div>

    );
  }
}

export default AnnouncementWidget;

