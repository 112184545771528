
import React from "react";


export class WinnersHistoryWidget extends React.Component {
  constructor(props) {
    super(props);
    this.currentRound = props.currentRound;
    this.randomLotto = props.randomLotto;
    this.withdrawReward = props.withdrawReward
    this.myUsdt = props.myUsdt;

    this.initialState = {    
      winners1: undefined,
      winners10: undefined,
      winners100: undefined,
      winners1000: undefined,
      selectedRound : this.currentRound - 1
    };
    
    this.state = this.initialState;

  };

  componentDidMount() {
    this.reload(this.currentRound - 1);
  }

  
  async reload (round){    
    this.setState({
      selectedRound : round
    })
    if (this.randomLotto != null) {
      const winner = await this.randomLotto.getWinnerMap(round);

      this.setState({
        winner1 : winner[0],
        winner10 : winner[1],
        winner100 : winner[2],
        winner1000 : winner[3],
      });
    }  
  }

   renderParticipants (type, participants) {
    return (
      <div className="mb-3">
          
        <div style={{border: '0.5px solid lightgray', padding: '10px', borderRadius: '5px'}}>
              <b>{type} Draw Winner Result</b>
              {participants ? 
                (<div style={{overflowX: 'auto'}}><table className="table table-striped">
                  <thead>
                    <tr>
                      <th style={{color: 'white', width:"27rem"}}>Address</th>
                      <th style={{color: 'white'}}>Winning amount<span className="d-none d-md-inline"> (USDT)</span></th>
                    </tr>
                  </thead>
                  <tbody>
                  {participants.map((participant, index) => (
                    <tr key={index}>
                      <td style={{color: 'white'}}>{participant.addr}</td>
                      <td style={{color: 'white'}}>{(Number(participant.amount) / 10**18).toFixed(2)}</td>
                    </tr>
                    ))
                  }
                  </tbody>
                </table></div>
              ): <div>No winner information available</div>}      
        </div>
      
      </div>    
      );
  }

  buttons() {
    const round = this.currentRound - 1;
    return (
      <div >
        <div className="d-flex justify-content-start">
          <div className="btn-group mt-3 mb-3" role="group" >
            {[...Array(11)].map((_, i) => (
              round - i > 0 && (
                <button 
                  key={i} 
                  type="button" 
                  className={`btn btn-sm ${this.state.selectedRound === round - i ? 'btn-warning' : 'btn-outline-secondary'}`}
                  onClick={() => this.reload(round - i)}
                >
                  Round {round - i}
                </button>
              )
            )).filter(Boolean)}
          </div>          
        </div>
      </div>
    );  
  }

  render() {
    return (
      <div className="mt-4">
        <h6>RandomLotto Draw Results</h6>
        {window.innerWidth <= 768 ? this.renderMenuMobileButtons() : this.renderMenuButtons()}
        <span style={{fontSize: '0.9rem', color: '#e0e0e0'}}>You can only check the winning records for the last 10 rounds.</span>
        {this.buttons()}
        {this.renderParticipants("1", this.state.winner1)}
        {this.renderParticipants("10", this.state.winner10)}
        {this.renderParticipants("100", this.state.winner100)}
        {this.renderParticipants("1000", this.state.winner1000)}
        <div  style={{color: 'red' ,fontSize:"0.9rem"}} className="mb-5">
          <div>- The RandomLotto Draw is not an investment, and all responsibility for participating in the draw lies with the participant.</div>
          <div>- Participating in the RandomLotto Draw is considered as agreeing to all the policies of the RandomLotto Draw, and the policies may be subject to change.</div>
          <div>- All participants should comply with the laws of their respective countries when deciding to participate in the RandomLotto Draw.</div>

        </div>

      </div>    

    );
  }


  renderMenuButtons = () => {
    return (
      <div className="mt-3">
        <div className="d-flex justify-content-around " style={{border: '0.5px solid lightgray', padding: '10px', borderRadius: '5px'}}>
          <div className="input-group">
            <span className="input-group-text" style={{backgroundColor: '#404040', color: 'white'}}>My Reward</span>
            <input 
            style={{backgroundColor: '#404040', color: 'white'}}
              type="text" 
              className="form-control" 
              value={this.myUsdt} 
              readOnly 
            />
            <span className="input-group-text" style={{backgroundColor: '#404040', color: 'white'}}>USDT</span>
            <button className="btn btn-success input-group-append" onClick={() => this.withdrawReward()}>Withdraw</button>
          </div>
        </div>
      </div>
    );
  }
  
  renderMenuMobileButtons = () => {
    return (
      <div className="mt-3" style={{border: '0.5px solid lightgray', padding: '10px', borderRadius: '5px'}}>
        <div style={{color: 'white'}}><span className="mr-3">My Reward</span><span style={{color: 'white'}}><b className="text-warning">{this.myUsdt}</b>  USDT</span></div>
        <button className="btn btn-success input-group-append btn-sm mt-3" onClick={() => this.withdrawReward()}>Withdraw</button>
      </div>
    );
  }

}

export default WinnersHistoryWidget;