import React from "react";

export function NoWalletDetected() {
  return (
    <div className="container" >
      <div className="row justify-content-md-center align-items-center h-100">
        <div className="col-12 p-4 text-center">
          <p>
            No Ethereum wallet was detected. <br />
            Please install one of the following<br/>
            <a
              href="https://www.coinbase.com/wallet"
              target="_blank"
              rel="noopener noreferrer"
            >
              Coinbase Wallet
            </a>
            or{" "}
            <a href="https://metamask.io" target="_blank" rel="noopener noreferrer">
              MetaMask
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
}
