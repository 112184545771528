import React from "react";


export class StatusPanel extends React.Component {
  constructor(props) {
    super(props);
    this.participantReview = props.participantReview;
    
  };

 /*
  a개 숫자중에서 b개의 숫자를 고를때 1~c t사이의 숫자가 적어도 1개이상 뽑힐 확률
 */
  probabilityAtLeastOneInRange(a, b, c) {
    // 각 숫자가 1~c 사이의 숫자가 아닐 확률
    const p_not_in_range = (a - c) / a;
    
    // b개의 숫자가 모두 1~c 사이의 숫자가 아닐 확률
    const prob_none_in_range = Math.pow(p_not_in_range, b);
    
    // 1~c 사이의 숫자가 적어도 1개 이상 뽑힐 확률
    const prob_at_least_one_in_range = 1 - prob_none_in_range;
    const p = (prob_at_least_one_in_range * 100).toFixed(2);
    return isNaN(p) ? 0 : p;
}
  render = () => {
    
    return (
      <div className="mt-3" style={{color: 'white'}}>
        <h6>Participation Status</h6>
        <div style={{border: '0.5px solid lightgray', padding: '10px', borderRadius: '5px', overflowX: 'auto'}}>
          
          <div style={{overflowX: 'auto'}}>
            <table className="table table-striped" style={{color: 'white'}}>
              <thead>
                <tr>
                  <th>Draw Type</th>
                  <th>Accumulated participation amount</th>
                  <th>My participation amount</th>
                  <th>Win more than once probability</th>
                </tr>
              </thead>
              <tbody>
                {this.participantReview.map((review, index)=>(
                <tr key={index} style={{backgroundColor: index % 2 !== 0 ? 'rgba(0, 128, 0, 0.2)' : ''}}>
                  <td>{review.partType.toString()} Winner Draw </td>
                  <td>{(Number(review.totalAmount.toString()) / 10**18).toFixed(0)} USDT</td>
                  <td>{(Number(review.myAmount.toString()) / 10**18).toFixed(0)} USDT</td>
                  <td>{
                    this.probabilityAtLeastOneInRange(
                      (Number(review.totalAmount.toString()) / 10**18).toFixed(0), 
                      review.partType, 
                      Math.floor(Number(review.myAmount.toString()) / 10**18), 1
                    )}%</td>
                </tr>
                ))}
              </tbody>
            </table>
            </div>
        </div>
      </div>      
    );
  }




}

export default StatusPanel;

