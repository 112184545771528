import React from "react";


export class ParticipationWidget extends React.Component {
  constructor(props) {
    super(props);
    this.selectedAddress = props.selectedAddress;
    this.Dapp = props.Dapp;
    this.participationErr = props.participationErr
  };

  render = () => {
    return (
      <div>        
        <div>
        Participation in the RandomLotto draw
        </div>
        <div className="mt-3" style={{border: '0.5px solid lightgray', padding: '10px', borderRadius: '5px'}}>
          {window.innerWidth <= 768 ? this.renderMobileMyAddress() : this.renderMyAddress()}
          {window.innerWidth <= 768 ? this.renderMobileParticipateButton() : this.renderParticipateButton()}
          <div style={{color: 'red'}}>
            <b>{this.participationErr}</b>
          </div>
          <div>
          <div>- You get one drawing chance per 1 USDT.</div>
          <div>- Each wallet can participate with a maximum of 1,000 USDT.</div>
          <div>- For every 1 USDT participation, 10 RLT(RandomLotto Token) will be rewarded.</div>
          </div>          
        </div>
        <div style={{fontSize: '0.9rem', color: '#f0f0f0'}}>
        For each drawing, the selection of winners will only proceed if the total accumulated participation amount exceeds 5,000 USDT. If the drawing does not take place, the accumulated participation amount will be carried over to the next round.
        </div>
      </div>
    );
  }

  renderMobileMyAddress = () => {
    return (         
      <div className=" mb-3">
        <div className="" style={{color: 'white'}}>My Address</div>
        <div className="text-warning" style={{color: 'white', wordBreak: 'break-all'}}>
          <b>{this.selectedAddress}</b>
        </div>
    </div>
    );
  }

  renderMyAddress = () => {
    return (         
      <div className="input-group mb-3">
        <span className="input-group-text" style={{backgroundColor: '#404040', color: 'white'}}>
        My Address
        </span>
        <input    
        style={{backgroundColor: '#404040', color: 'white'}}
        disabled
        value={this.selectedAddress}         
          className="form-control" 
        />
    </div>
    );
  }



  renderParticipateButton = () =>{
    return (          
    <div className="input-group mb-3">
      <span className="input-group-text" style={{backgroundColor: '#404040', color: 'white'}}>
      Participation USDT
      </span>
      <input 
        placeholder="Please enter USDT. Input 1 or more"
        style={{backgroundColor: '#404040', color: 'white'}}
        type="number" 
        className="form-control" 
        aria-describedby="button-addon2" 
        id="wallet1-input"
        ref={(input) => this.wallet1Input = input}
        min="0"
        onInput={(e) => {
          if (e.target.value < 0) e.target.value = 0;
        }}
      />
      <select 
        className="form-select" 
        ref={(select) => this.wallet1GameType = select}
      >
        <option value="" disabled>Select participation type</option>
        <option value="1" defaultValue>1 Winner Draw </option>
        <option value="10">10 Winner Draw </option>
        <option value="100">100 Winner Draw </option>
        <option value="1000">1000 Winner Draw </option>
      </select>
      <button 
        className="btn btn-success" 
        type="button" 
        id="button-addon2" 
        onClick={() => this.Dapp._lotto(this.wallet1GameType.value, this.wallet1Input.value)}
      >
        Participation
      </button>
    </div>
    );
  }



  renderMobileParticipateButton = () =>{
    return (          
    <div className=" mb-3">
      <span className="" style={{ color: 'white'}}>
      Participate with USDT
      </span>
        <input 
        style={{backgroundColor: 'white', color: 'black'}}
          type="number" 
          placeholder="Please enter USDT. Input 1 or more"
          className="form-control form-control-sm mt-2" 
          aria-describedby="button-addon2" 
          id="wallet1-input"
          ref={(input) => this.wallet1Input = input}
          min="0"
          onInput={(e) => {
            if (e.target.value < 0) e.target.value = 0;
          }}
  
        />
        <select 
          className="form-control form-control-sm mt-2" 
          ref={(select) => this.wallet1GameType = select}
        >
          <option value="" disabled>Select participation type</option>
          <option value="1" defaultValue>1 Winner Draw </option>
          <option value="10">10 Winner Draw </option>
          <option value="100">100 Winner Draw </option>
          <option value="1000">1000 Winner Draw </option>
        </select>
      <button 
        className="btn btn-success btn-sm mt-2" 
        type="button" 
        id="button-addon2" 
        onClick={() => this.Dapp._lotto(this.wallet1GameType.value, this.wallet1Input.value)}
      >
        Participation
      </button>
    </div>
    );
  }




}

export default ParticipationWidget;

